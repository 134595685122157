const appConfig={
    // apiPrefix: process.env.NODE_ENV==="development"? `http://localhost:5000/api/v1`:`/api/v1`,
    apiPrefix: `/api/v1`,
    authenticatedEntryPath: '/home',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'en',
    // enableMock: true,
}

export default appConfig
